import React, {useState} from 'react';
import SignOutButton from '../Account/Sign/sign-out';
import * as ROUTES from '../../constants/routes';
import {AuthUserContext} from '../Session';
import {Link} from 'react-router-dom';
import CurrentUser from "../Account/current-user";
import * as ROLES from '../../constants/roles';
import {Navbar, Container, Nav, NavDropdown, Offcanvas} from "react-bootstrap";
import {BsReverseLayoutTextWindowReverse} from "react-icons/bs";
import {GrGroup} from "react-icons/gr";
import {SiProgress} from "react-icons/si";
import {FiLogIn} from "react-icons/fi";
import {HiOutlinePencil} from "react-icons/hi";
import {MdOutlineDashboardCustomize} from "react-icons/md";

const Navigation = () => (
    <>
        <AuthUserContext.Consumer>
            {(authUser) => (authUser && authUser.role === ROLES.ADMIN ? <AdminNavigation/> : '')}
        </AuthUserContext.Consumer>
        <NavigationBootstrap/>
    </>
);

const NavigationBootstrap = () => {
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleOffcanvasClose = () => setShowOffcanvas(false);
    const handleOffcanvasShow = () => setShowOffcanvas(true);

    return (
        <>
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <img className="brand-logo" alt="prosurfingtools-logo" src="/logo192.png" width={35}/>
                    <Navbar.Brand className="navbar-brand-pro brand" as={Link} to={ROUTES.LANDING}>
                        prosurfing<span className="brand-v">tools</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleOffcanvasShow}/>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <AuthUserContext.Consumer>
                            {(authUser) => (authUser ? <NavigationAuth/> : <NavigationNonAuth/>)}
                        </AuthUserContext.Consumer>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Offcanvas show={showOffcanvas} onHide={handleOffcanvasClose}>
                <Offcanvas.Header closeButton>
                    <img className="brand-logo" alt="prosurfingtools-logo" src="/logo192.png" width={35}/>
                    <Navbar.Brand className="navbar-brand-pro brand" as={Link} to={ROUTES.LANDING}>
                        prosurfing<span className="brand-v">tools</span>
                    </Navbar.Brand>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <AuthUserContext.Consumer>
                        {(authUser) => (authUser ? <NavigationAuth handleClose={handleOffcanvasClose}/> :
                            <NavigationNonAuth handleClose={handleOffcanvasClose}/>)}
                    </AuthUserContext.Consumer>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
};


const NavigationAuth = ({handleClose}) => (
    <Nav className="flex-column justify-content-end nav-icons" onSelect={handleClose}>
        <NavDropdown title={<CurrentUser/>} id="collasible-nav-dropdown">
            <NavDropdown.Item as={Link} to={ROUTES.ACCOUNT} onClick={handleClose}>Account</NavDropdown.Item>
            <NavDropdown.Divider/>
            <NavDropdown.Item>{<SignOutButton/>}</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link as={Link} to={ROUTES.DASHBOARD} onClick={handleClose} className="nav-link link-color">
            <MdOutlineDashboardCustomize/> <span className="nav-text">Dashboard</span>
        </Nav.Link>
        <Nav.Link as={Link} to={ROUTES.TEAM} onClick={handleClose} className="nav-link link-color">
            <GrGroup/> <span className="nav-text">My Team</span>
        </Nav.Link>
        <Nav.Link as={Link} to={ROUTES.SUBSCRIBE} onClick={handleClose} className="nav-link link-color link-register">
            <SiProgress/> <span className="nav-text">Pro</span>
        </Nav.Link>
        {/*<Notifications/>*/}
    </Nav>
);

const NavigationNonAuth = ({handleClose}) => (
    <Nav className="justify-content-end flex-grow-1 pe-3 nav-icons" onSelect={handleClose}>
        <Nav.Link as={Link} to={ROUTES.SIGN_IN} onClick={handleClose} className="nav-link link-color nav-link">
            <FiLogIn className="sign-icon"/> <span className="nav-text">Sign In</span>
        </Nav.Link>
        <Nav.Link as={Link} to={ROUTES.SIGN_UP} onClick={handleClose} className="nav-link link-color nav-link link-register">
            <HiOutlinePencil className="sign-icon"/> <span className="nav-text">Register</span>
        </Nav.Link>
    </Nav>
);

const AdminNavigation = (authUser) => (
    <Navbar expand="lg" sticky="top" className="admin-nav">
        <Container>
            <Navbar.Brand as={Link} to={ROUTES.ADMIN}><BsReverseLayoutTextWindowReverse/></Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                    <AuthUserContext.Consumer>
                        {(authUser) => (authUser && authUser.role === ROLES.ADMIN ? authUser.email : '')}
                    </AuthUserContext.Consumer>
                </Navbar.Text>
            </Navbar.Collapse>
        </Container>
    </Navbar>
);

export default Navigation;
